import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office
import fco from "assets/img/clients_logos/fco.png";
// import office1 from "assets/img/examples/office1.jpg";
import wysensing from "assets/img/clients_logos/wysensing.png";
import ideiasXL from "assets/img/clients_logos/ideiasXL.png";
import inideia from "assets/img/clients_logos/inideia.png";
import ts from "assets/img/clients_logos/ts.png";
import t4e from "assets/img/clients_logos/t4e.png";

const useStyles = makeStyles(officeStyle);

export default function SectionClients() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <GridContainer className={classes.textCenter}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.title}>
            Some of the companies that work with us every day
          </h2>
          <h4 className={classes.description}>...</h4>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={3} sm={6} xs={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={fco}
            alt="office1"
          />
        </GridItem>
        <GridItem md={3} sm={6} xs={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={wysensing}
            alt="office2"
          />
        </GridItem>
        <GridItem md={3} sm={6} xs={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={ideiasXL}
            alt="office3"
          />
        </GridItem>
        <GridItem md={3} sm={6} xs={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={inideia}
            alt="office4"
          />
        </GridItem>
        <GridItem md={3} sm={6} xs={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={ts}
            alt="office5"
          />
        </GridItem>
        <GridItem md={3} sm={6} xs={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={t4e}
            alt="office5"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
