import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import LanguageIcon from "@material-ui/icons/Language";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
// import ThreeDRotationIcon from "@material-ui/icons/ThreeDRotation";
import { ReactComponent as DModel } from "assets/icons/3d-model.svg";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>Custom made software</h2>
          <h4 className={classes.title}>
            We design, plan, implement and integrate
          </h4>
          {/* <br /> */}
          <h5 className={classes.description}>
            <b>TECHNOTION</b> is a company focused on developing software
            tailored to the real needs of our customers.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Mobile Applications"
              description="We develop Android and IOS applications B2B and B2C customized to your business with the highest standards of performance and usability."
              icon={MobileFriendlyIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Web development"
              description="Customized management system with dynamic and interactive dashboards in order to support decision making."
              icon={LanguageIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="E-Commerce"
              description=""
              icon={AddShoppingCartIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Projects 3D"
              description=""
              icon={DModel}
              iconColor="primary"
              vertical
              svg
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
