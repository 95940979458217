import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import HeaderLinks from "./HeaderLinks";
import Header from "components/Header/Header";

import logo from "assets/img/logo.svg";
const useStylesLogo = makeStyles({
  logo: {
    maxWidth: 220,
  },
});

const HeaderCustom = () => {
  const classesLogo = useStylesLogo();

  return (
    <Header
      // absolute
      brand={<img src={logo} className={classesLogo.logo} />}
      color="transparent"
      fixed
      changeColorOnScroll={{
        height: 1,
        color: "white",
      }}
      links={<HeaderLinks></HeaderLinks>}
    />
  );
};

export default HeaderCustom;
