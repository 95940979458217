import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import { SvgIcon } from "@material-ui/core";

import styles from "assets/jss/material-kit-pro-react/components/infoStyle.js";

const useStyles = makeStyles(styles);
// ml410_zJ
export default function InfoArea(props) {
  const {
    title,
    description,
    iconColor,
    descriptionColor,
    vertical,
    svg,
    className,
  } = props;
  console.log(svg);
  const classes = useStyles();
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[iconColor]]: true,
    [classes.iconWrapperVertical]: vertical,
  });

  const descriptionWrapper = classNames({
    [classes.descriptionWrapper]: true,
    [classes[descriptionColor]]: true,
  });
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.iconVertical]: vertical,
  });
  const infoAreaClasses = classNames({
    [classes.infoArea]: true,
    [className]: className !== undefined,
  });
  let icon = null;
  switch (typeof props.icon) {
    case svg:
      icon = <SvgIcon className={iconClasses}>{props.icon}</SvgIcon>;
      break;
    case "string":
      icon = <Icon className={iconClasses}>{props.icon}</Icon>;
      break;
    default:
      icon = <props.icon className={iconClasses} />;
      break;
  }
  return (
    <div className={infoAreaClasses}>
      <div className={iconWrapper}>{icon}</div>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <div className={classes.description}>
          <span className={descriptionWrapper}>{description}</span>
        </div>
      </div>
    </div>
  );
}

InfoArea.defaultProps = {
  iconColor: "gray",
  descriptionColor: "gray",
  svg: false,
};

InfoArea.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  iconColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
    "snow",
  ]),
  descriptionColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
    "snow",
  ]),
  vertical: PropTypes.bool,
  svg: PropTypes.bool,
  className: PropTypes.string,
};
