import React from "react";
import Carousel from "react-slick";
// import imagesSlider from "./ImagesSlider";
import { makeStyles } from "@material-ui/core/styles";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
const useStylesHeader = makeStyles(headersStyle);
// eslint-disable-next-line no-unused-vars
import { display } from "@material-ui/system";

import mXL from "assets/img/slider/mobile/XL.png";
import mT4E from "assets/img/slider/mobile/T4E.png";
import mPT from "assets/img/slider/mobile/PuraTernura.png";
import mNC from "assets/img/slider/mobile/NorthCamp.png";

import dXL from "assets/img/slider/desktop/XL.png";
import dPT from "assets/img/slider/desktop/PuraTernura.png";
import dT4E from "assets/img/slider/desktop/T4E.png";
import dNC from "assets/img/slider/desktop/NorthCamp.png";

const Slider = () => {
  const classes = useStylesHeader();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    objectFit: "cover",
    autoplay: false,
  };

  const width = window.innerWidth;
  console.log(width);
  if (width < 700)
    return (
      <Carousel {...settings}>
        <div key="1">
          <div
            className={classes.pageHeader}
            style={{ backgroundImage: `url("${mXL}")` }}
          ></div>
        </div>
        <div key="2">
          <div
            className={classes.pageHeader}
            style={{ backgroundImage: `url("${mT4E}")` }}
          ></div>
        </div>
        <div key="3">
          <div
            className={classes.pageHeader}
            style={{ backgroundImage: `url("${mPT}")` }}
          ></div>
        </div>
        <div key="4">
          <div
            className={classes.pageHeader}
            style={{ backgroundImage: `url("${mNC}")` }}
          ></div>
        </div>
      </Carousel>
    );
  else
    return (
      <Carousel {...settings}>
        <div key="1">
          <div
            className={classes.pageHeader}
            style={{ backgroundImage: `url("${dXL}")` }}
          ></div>
        </div>
        <div key="2">
          <div
            className={classes.pageHeader}
            style={{ backgroundImage: `url("${dT4E}")` }}
          ></div>
        </div>
        <div key="3">
          <div
            className={classes.pageHeader}
            style={{ backgroundImage: `url("${dPT}")` }}
          ></div>
        </div>
        <div key="4">
          <div
            className={classes.pageHeader}
            style={{ backgroundImage: `url("${dNC}")` }}
          ></div>
        </div>
      </Carousel>
    );
};

export default Slider;

{
  /* <Carousel {...settings}>
{imagesSlider.map((stuff, i) => {
  return (
    <div key={i}>
      <div
        className={classes.pageHeader}
        style={{ backgroundImage: `url("${stuff.imageUrl}")` }}
      ></div>
    </div>
  );
})}
</Carousel> */
}
